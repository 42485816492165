import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import i18n from 'i18n';
import Link from 'components/Link';
import Logo from 'components/Logo/LogoRanking';
import HeaderGeneric, { getRootPath } from './HeaderGeneric';
import HeaderBannerQm from './HeaderBannerQm';

// eslint-disable-next-line react/prop-types
const Brand = ({ lang }) => (
  <Link
    className="navbar-brand d-flex"
    route={getRootPath(lang, 'qm-wijzer')}
    alt={i18n.t('SECTION_RANKING', { count: 4 })}
    style={{ lineHeight: '23px' }}>
    <Logo height={23} alt="" style={{ marginRight: 6 }} />{' '}
    {i18n.t('SECTION_RANKING', { count: 4 })}
  </Link>
);

function HeaderRanking({ lang, ...rest }) {
  return (
    <Fragment>
      <HeaderBannerQm lang={lang} />
      <HeaderGeneric lang={lang} brand={<Brand lang={lang} />} {...rest} />
    </Fragment>
  );
}

HeaderRanking.propTypes = {
  lang: PropTypes.string
};

export default HeaderRanking;
