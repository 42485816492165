import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import classnames from 'classnames';
import i18n from 'i18n';
import debounce from 'debounce';
import { fetchSearch } from 'actions/products';
import Link from 'components/Link';
import ThreeBounce from 'components/ThreeBounce';
import ProductListItem from 'components/ProductListItem';
import media from 'components/Media';
import { Router } from 'router';
import slugify from 'lib/slugify';

const SEARCH_MIN_CHARS = 2;

class Search extends Component {
  constructor(props) {
    super(props);
    this.searchContainer = React.createRef();
  }

  state = {
    showSearchResults: false,
    q: this.props.q || ''
  };

  onSearchFocus = () => {
    const { q } = this.state;
    if (!this.props.products.search.length) {
      this.search(q);
    } else if (!this.state.showSearchResults) {
      this.setState({ showSearchResults: true });
      this.props.onOverlay(q.length > SEARCH_MIN_CHARS);
    }
  };

  onInputChange = (e) => {
    const q = (e.target.value || '').trim();
    this.setState({ q, showSearchResults: true });
    this.search(q);
  };

  search = (q) => {
    // Enter min chars required
    if (q.length <= SEARCH_MIN_CHARS) return;
    this.props.dispatch(fetchSearch({ q, per_page: 6, detail: 'health' }));
  };

  onSearchClickOutside = (e) => {
    const clickedOutside =
      this.searchContainer.current &&
      !this.searchContainer.current.contains(e.target);
    this.setState({
      showSearchResults: !clickedOutside
    });
    this.props.onOverlay(
      !clickedOutside && this.state.q.length > SEARCH_MIN_CHARS
    );
  };

  onSubmit = (term) => {
    if (this.props.onResultClick) return;
    this.setState({ showSearchResults: false });
    const app = this.context.app;
    let path = `/search?q=${term}`;
    if (app) path = `${path}&app=${app}`;
    Router.pushRoute(path);
  };

  onResultClick = (item) => (e) => {
    e.preventDefault();
    e.stopPropagation();
    this.setState({ showSearchResults: false });
    if (this.props.onResultClick) return this.props.onResultClick(item);
    const app = this.context.app;
    let path = `/products/${slugify(item.id, item.name)}`;
    if (app) path = `${path}?app=${app}`;
    Router.pushRoute(path);
  };

  componentDidMount() {
    document.addEventListener('mousedown', this.onSearchClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.onSearchClickOutside);
  }

  componentDidUpdate(prevProps, prevState) {
    this.props.onOverlay(
      this.state.q.length > SEARCH_MIN_CHARS && this.state.showSearchResults
    );
  }

  render() {
    const { products, size, className, focus, ...rest } = this.props;
    const { q, showSearchResults } = this.state;
    return (
      <div className="position-relative" ref={this.searchContainer} {...rest}>
        <SearchFormInput
          className={classnames('form-inline', className)}
          q={q}
          size={size}
          focus={focus}
          onFocus={this.onSearchFocus}
          searching={products.searching}
          onChange={this.onInputChange}
          onSubmit={this.onSubmit}
        />
        {showSearchResults && q.length > SEARCH_MIN_CHARS && (
          <SearchResults
            q={q}
            searching={products.searching}
            results={products.search}
            total={products.total}
            onResultClick={this.onResultClick}
          />
        )}
      </div>
    );
  }
}

export default connect(({ products }) => ({ products }))(Search);

Search.propTypes = {
  products: PropTypes.object,
  dispatch: PropTypes.func,
  q: PropTypes.string,
  size: PropTypes.oneOf(['lg', 'sm']),
  onOverlay: PropTypes.func,
  className: PropTypes.string,
  onResultClick: PropTypes.func,
  focus: PropTypes.bool
};

Search.defaultProps = {
  onOverlay: () => {}
};

Search.contextTypes = {
  app: PropTypes.string
};

// @todo
// Add arrow key traversal

export class SearchFormInput extends Component {
  constructor(props) {
    super(props);
    this.debounced = debounce(this.props.onChange, 500);
    this.textInput = React.createRef();
  }

  componentDidMount() {
    if (this.props.focus) {
      this.textInput.current.focus();
    }
  }

  onSubmit = (e) => {
    e.preventDefault();
    const term = this.textInput.current.value;
    if (this.props.onSubmit) this.props.onSubmit(term);
  };

  inputChange = (e) => {
    e.persist();
    this.debounced(e);
  };

  render() {
    const {
      searching,
      q,
      size,
      onChange,
      onFocus,
      onSubmit, // eslint-disable-line
      ...rest
    } = this.props;
    return (
      <SearchForm action="/search" onSubmit={this.onSubmit} {...rest}>
        <FormGroup>
          <input
            name="q"
            defaultValue={q}
            className={classnames('form-control', {
              'form-control-lg': size === 'lg',
              'form-control-sm': size === 'sm'
            })}
            ref={this.textInput}
            type="text"
            autoComplete="off"
            autoCorrect="off"
            autoCapitalize="off"
            spellCheck="false"
            placeholder={i18n.t('SEARCH')}
            onChange={this.inputChange}
            onFocus={onFocus}
          />
          {searching && <Searching color="#aaa" size={36} />}
        </FormGroup>
      </SearchForm>
    );
  }
}

SearchFormInput.propTypes = {
  q: PropTypes.string,
  size: PropTypes.oneOf(['lg', 'sm']),
  searching: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  onFocus: PropTypes.func,
  className: PropTypes.string,
  onSubmit: PropTypes.func,
  focus: PropTypes.bool
};

SearchFormInput.defaultProps = {
  q: '',
  searching: false
};

export function SearchResults({ results, total, q, searching, onResultClick }) {
  if (!q || searching) return null;
  const noResults = !total || !results.length;
  if (!searching && noResults) {
    return <NoResults>{i18n.t('NO_SEARCH_RESULTS')}</NoResults>;
  }
  return (
    <StyledResults>
      {results.map((item, index) => (
        <ProductListItem
          key={index}
          item={item}
          search
          onClick={onResultClick(item)}
        />
      ))}
      <Link route={`/search?q=${q}`} className="list-group-item">
        {i18n.t('SHOW_ALL_N_RESULTS', { count: total })}
      </Link>
    </StyledResults>
  );
}

SearchResults.propTypes = {
  results: PropTypes.array,
  total: PropTypes.number,
  q: PropTypes.string,
  searching: PropTypes.bool,
  onResultClick: PropTypes.func
};

export const SearchOverlay = styled.div`
  transition: 0.3s;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  height: 100%;
  background: transparent;
  z-index: 1000;
  &.open {
    background: rgba(0, 0, 0, 0.4);
    width: 100%;
  }
`;

const NoResults = styled.div`
  color: #aaa;
  font-size: 12px;
  position: absolute;
`;

const StyledResults = styled.div.attrs({
  className: 'list-group'
})`
  position: absolute;
  width: 400px;
  top: 45px;
  z-index: 9999;
  ${media.down.phone`
    width: 100%;
  `};
`;

const Searching = styled(ThreeBounce)`
  position: absolute;
  right: 5px;
  top: 10px;
`;

const SearchForm = styled.form`
  margin-top: 0;
  margin-bottom: 0;

  input {
    background: #f1f3f4 url('/static/images/icon-search.svg') no-repeat;
    background-position: 10px 50%;
    padding-left: 2.5rem;
    background-size: 20px;
    border: 0;
    outline: none;
    &:focus {
      box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2),
        0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
    }
  }
  ${media.down.desktop`
    input {
      padding-left: 2.2rem;
    }
    input.form-control-lg {
      padding-left: 2.2rem;
    }
  `};

  // Chrome input box width issue
  // https://github.com/q-m/questionmark-web/issues/239
  &:not(.navbar-form) input {
    width: 270px !important;
  }
  @media (min-width: 1100px) {
    &.navbar-form input {
      width: 270px;
    }
  }
`;

const FormGroup = styled.div.attrs({
  className: 'form-group'
})`
  margin: 0;
  position: relative;
  ${media.down.desktop`
    width: 100%;
    input {
      width: 100% !important;
    }
  `};
`;
