import React from 'react';

import { GA_CONVERSION_ID, GA_CONVERSION_LABEL } from '../config';

export default function Adwords() {
  if (!GA_CONVERSION_ID || !GA_CONVERSION_LABEL) {
    return null;
  }

  return (
    <div>
      {/* Google Code for Contact aanvraag Conversion Page */}
      <script
        type="text/javascript"
        dangerouslySetInnerHTML={{
          __html: `
          /* <![CDATA[ */
          var google_conversion_id = ${GA_CONVERSION_ID};
          var google_conversion_label = ${JSON.stringify(GA_CONVERSION_LABEL)};
          var google_remarketing_only = false;
          /* ]]> */`
        }}
      />
      <script
        type="text/javascript"
        src="//www.googleadservices.com/pagead/conversion.js"
      />
      <noscript>
        <div style={{ display: 'inline' }}>
          <img
            height="1"
            width="1"
            style={{ borderStyle: 'none' }}
            alt=""
            src={`//www.googleadservices.com/pagead/conversion/${GA_CONVERSION_ID}/?label=${GA_CONVERSION_LABEL}&guid=ON&script=0`}
          />
        </div>
      </noscript>
    </div>
  );
}
