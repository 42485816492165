import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Row } from 'reactstrap';
import styled from 'styled-components';
import media from 'components/Media';
import BoxWhite from 'components/BoxWhite';
import Link from 'components/Link';
import TimeAgo from 'components/TimeAgo';

export default class ListCards extends Component {
  render() {
    const { items, sm, md, lg, ...rest } = this.props;
    return (
      <Row {...rest}>
        {items.map((item, index) => (
          <ListCardItem key={index} route={item.url} {...{ sm, md, lg }}>
            <ListCardItemWrapper
              className="d-flex flex-column"
              style={{ height: '100%' }}>
              <ListCardItemCover
                bg={item.cover_image}
                style={{ height: 170 }}
              />
              <div className="p-4 p-sm-4">
                <div className="text-muted">
                  <TimeAgo date={item.published_on} />
                </div>
                <h4>{item.name}</h4>
              </div>
            </ListCardItemWrapper>
          </ListCardItem>
        ))}
      </Row>
    );
  }
}

ListCards.propTypes = {
  items: PropTypes.array.isRequired,
  sm: PropTypes.int,
  md: PropTypes.int,
  lg: PropTypes.int
};

ListCards.defaultProps = {
  items: []
};

ListCards.contextTypes = {
  lang: PropTypes.string
};

export const ListCardItem = styled(Link).attrs(({ sm, md, lg }) => ({
  className: `my-3 my-sm-4 col-12 col-sm-${sm} col-md-${md} col-lg-${lg}`
}))`
  color: inherit;
  text-decoration: none;
  &:hover {
    text-decoration: none;
    color: inherit;
  }
`;

ListCardItem.defaultProps = {
  sm: 12,
  md: 12,
  lg: 6
};

export const ListCardItemWrapper = styled(BoxWhite).attrs({
  className: 'rounded'
})`
  overflow: hidden;
  transition: 0.3s;
  a {
    color: ${(props) => props.theme.colors.ship};
  }

  &.with-cover {
    a {
      color: white;
    }
  }

  ${media.down.desktop`
    max-width: 600px;
  `};

  &:hover {
    box-shadow: 0 7px 20px rgba(0, 0, 0, 0.2);
  }
`;

export const ListCardItemCover = styled.div.attrs({
  className: 'p-5'
})`
  background: ${(props) =>
    `linear-gradient(rgba(0, 0, 0, 0.35), rgba(0, 0, 0, 0.25)),
    ${(props.bg && `url(${props.bg})`) || 'none'}`};
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  overflow: hidden;
  text-overflow: ellipsis;
  transition: 0.3s;
  z-index: 1;
  ${ListCardItemWrapper}:hover & {
    transform: scale(1.03, 1.03);
  }
`;
