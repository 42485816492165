import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import styled, { ThemeProvider, createGlobalStyle } from 'styled-components';
import i18n from 'i18n';
import Header from 'components/Header';
import HeaderApp from 'components/Header/HeaderApp';
import media from 'components/Media';
import Footer from 'components/Footer';
import Meta from 'components/Meta';
import PageCTA from 'components/PageCTA';
import Related from 'components/Related';
import Link from 'components/Link';
import { BackApp } from 'components/Back';
import PageTitle from 'components/PageTitle';
import Markup from 'components/Markup';
import Blocks from 'components/Blocks';
import TimeAgo from 'components/TimeAgo';
import PageContainer, { Wrap } from 'components/PageContainer';
import InPage from 'components/Nav/InPage';
import LeftSidebar from 'components/Nav/LeftSidebar';
import 'components/Loader';
import { SearchOverlay } from 'components/Search';
import theme from 'lib/theme';
import { isHome } from 'lib/view-helpers';
import localized from 'router/localized-types';
import prismic from '../prismic';

const aboutUrl = `/${localized.nl.about_main}`;

// Layout for mobile app
function LayoutApp({ meta, children, url, cta }) {
  // Local URLs are opened in the mobile app, all others in the mobile system web browser.
  const localUrls = `/index-app ${aboutUrl} /lookup /404 /contact /search /categories /categories/* /products/*`;

  return (
    <ThemeProvider theme={theme}>
      <div className="page" data-app-local-urls={localUrls}>
        <Meta {...meta} />
        <ResponsiveStyles />
        <HeaderApp url={url} />
        {!isHome(url) && <BackApp />}
        <PageContent>{children}</PageContent>
        {!isHome(url) && (
          <RoundedRight>
            <PageCTA cta={cta} />
          </RoundedRight>
        )}
        <div className="my-4 text-center">
          <Link route={aboutUrl} className="mr-3" data-app-target="_blank">
            {i18n.t('ABOUT')}
          </Link>
          <Link route="/contact" data-app-target="_blank">
            {i18n.t('CONTACT')}
          </Link>
        </div>
      </div>
    </ThemeProvider>
  );
}

LayoutApp.propTypes = {
  meta: PropTypes.object,
  children: PropTypes.node,
  url: PropTypes.object,
  cta: PropTypes.object
};

function PageWithNav({
  hasPageNav,
  body,
  blocks,
  display_in_page_nav,
  preset,
  children,
  nav,
  dept,
  published_on,
  name
}) {
  if (!hasPageNav) return <>{children}</>;
  const { sidebar } = prismic.nav(nav);
  const blockWithTitles = blocks.filter((b) => b.title);
  const hasSidebar = dept && sidebar[dept] && sidebar[dept].length > 0;
  const displayInPageNav = display_in_page_nav && blockWithTitles.length > 0;
  return (
    <PageContainer fluid>
      <div className="row">
        {hasSidebar && (
          <div className="col-xl-3 pl-5 pl-sm-0">
            <LeftSidebar items={sidebar[dept]} />
          </div>
        )}
        <div
          className={classnames({
            'col-xl-9': hasSidebar,
            'col-xl-12': !hasSidebar
          })}>
          <PageTitle>{name}</PageTitle>
          <Wrap>
            <p className="text-muted">
              <TimeAgo date={published_on} />
            </p>

            {!displayInPageNav && <Markup>{body}</Markup>}

            {displayInPageNav && (
              <div className="row">
                <div className="col-lg-8 order-1 order-lg-0">
                  <Markup>{body}</Markup>
                </div>
                <InPage
                  className="col-lg-4 order-0 order-lg-1"
                  blocks={blockWithTitles}
                />
              </div>
            )}
          </Wrap>
          <Blocks items={blocks} preset={preset} />
        </div>
      </div>
    </PageContainer>
  );
}

const pageNavProps = {
  hasPageNav: PropTypes.bool,
  body: PropTypes.object,
  blocks: PropTypes.array,
  display_in_page_nav: PropTypes.bool,
  preset: PropTypes.string,
  children: PropTypes.node,
  nav: PropTypes.object,
  dept: PropTypes.string,
  published_on: PropTypes.string,
  name: PropTypes.string
};

PageWithNav.propTypes = pageNavProps;

class Layout extends Component {
  state = {
    overlay: false
  };

  onOverlay = (overlay) => this.setState({ overlay });

  componentDidMount() {
    // Track google analytics page views
    if (!window.ga) return;
    // send path with query string
    window.ga(
      'send',
      'pageview',
      window.location.href.replace(window.location.origin, '')
    );
  }

  render() {
    const {
      meta,
      related,
      cta,
      children,
      nav,
      lang,
      url,
      hasCover,
      app,
      banner,
      dept
    } = this.props;
    if (app) return <LayoutApp {...{ meta, children, lang, url, cta }} />;

    const { header: headerNav, footer: footerNav } = prismic.nav(nav);

    const {
      hasPageNav,
      body,
      blocks,
      display_in_page_nav,
      preset,
      name,
      published_on
    } = this.props;
    const pageWithNavProps = {
      hasPageNav,
      body,
      blocks,
      display_in_page_nav,
      preset,
      nav,
      dept,
      name,
      published_on
    };

    return (
      <ThemeProvider theme={theme}>
        <div className="page">
          <Meta {...meta} />
          <ResponsiveStyles />
          {banner}
          <Header
            lang={lang}
            links={headerNav}
            url={url}
            dept={dept}
            onOverlay={this.onOverlay}
            className={classnames({ 'bg-white': !hasCover })}
          />
          <div className="position-relative">
            <SearchOverlay
              className={classnames({ open: this.state.overlay })}
            />
            <PageWithNav {...pageWithNavProps}>
              <PageContent>{children}</PageContent>
            </PageWithNav>
            <div
              className={classnames(
                'container-fluid d-lg-flex align-items-lg-end p-0 mt-sm-5',
                {
                  'mb-lg-5': related.length > 0
                }
              )}>
              <Left>
                <RoundedRight>
                  <PageCTA cta={cta} />
                </RoundedRight>
              </Left>
              <Right>
                <RoundedLeft>
                  <Related items={related} />
                </RoundedLeft>
              </Right>
            </div>
            {related.length > 0 && (
              <div className="mb-lg-5 d-none d-lg-block">&nbsp;</div>
            )}
            <Footer links={footerNav} dept={dept} />
          </div>
        </div>
      </ThemeProvider>
    );
  }
}

Layout.propTypes = {
  meta: PropTypes.object,
  related: PropTypes.array,
  cta: PropTypes.object,
  children: PropTypes.node,
  nav: PropTypes.object,
  lang: PropTypes.string,
  url: PropTypes.object,
  hasCover: PropTypes.bool,
  app: PropTypes.string,
  banner: PropTypes.node,
  dept: PropTypes.string,
  ...pageNavProps
};

Layout.defaultProps = {
  meta: {},
  related: [],
  cta: {},
  nav: {},
  lang: 'nl',
  url: {},
  hasCover: false
};

export default Layout;

// Use 'body { ... }' to get specificity higher than the bootstrap style (in _app.js).
const ResponsiveStyles = createGlobalStyle`
  body {
    ${media.down.tablet`
      h1, .h1 {
        font-size: 2.133rem;
        line-height: 2.933rem;
      }
      h2, .h2 {
        font-size: 1.6rem;
        line-height: 2.133rem;
      }
      h3, .h3 {
        font-size: 1.333rem;
        line-height: 1.867rem;
      }
      h4, .h4 {
        font-size: 1.2rem;
        line-height: 1.7rem;
      }
      h5, .h5 {
        font-size: 1rem;
        line-height: 1.566rem;
      }
      h6, .h6 {
        font-size: 0.9rem;
        line-height: 1.4rem;
      }
    `}

    ${media.up.desktop`
      .overlap-y-lg {
        margin-top: -4.5rem !important;
      }
      .overlap-x-lg {
        margin-left: -4.5rem;
      }
    `}
  }
`;

const PageContent = styled.div``;

const Left = styled.div.attrs({
  className: 'col-12 col-lg-6 p-0 position-relative'
})`
  z-index: 99;
`;

const Right = styled.div.attrs({
  className: 'col-12 col-lg-6 p-0'
})`
  ${media.up.desktop`
    margin-bottom: -3rem;
  `};
`;

const RoundedRight = styled.div.attrs({
  className: 'bg-flamingo text-white'
})`
  ${media.up.desktop`
    border-top-right-radius: .25rem!important;
    border-bottom-right-radius: .25rem!important;
    margin-right: -1.5rem;
    position: relative;
    padding-left: 4.5rem;
  `};

  ${media.up.large`
    padding-left: 6rem;
  `};

  ${media.up.giant`
    padding-left: 7.5rem;
  `};
`;

const RoundedLeft = styled.div.attrs({
  className: 'bg-ship text-white'
})`
  ${media.up.desktop`
    border-top-left-radius: .25rem!important;
    border-bottom-left-radius: .25rem!important;
    padding-left: 3rem;
  `};
`;
