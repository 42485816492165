import React, { Component } from 'react';
import PropTypes from 'prop-types';
import i18n from 'i18n';
import Link from 'components/Link';

import Nav from './Nav';
import Logo from 'components/Logo/LogoQm';
import { scanURL } from 'lib/app-helpers';

export class HeaderApp extends Component {
  render() {
    return (
      <Nav className="navbar navbar-light navbar-expand-lg py-3">
        <Link
          className="navbar-brand mr-0"
          route="/index-app"
          alt={i18n.t('QUESTIONMARK')}>
          <Logo />
        </Link>
        <ul className="navbar-nav ml-auto">
          <li>
            <a
              className="btn btn-primary btn-sm"
              href={scanURL(this.context.app)}>
              Scan &nbsp;
              <i className="fa fa-barcode" style={{ color: 'white' }} />
            </a>
          </li>
        </ul>
      </Nav>
    );
  }
}

HeaderApp.contextTypes = {
  app: PropTypes.string
};

export default HeaderApp;
