import React from 'react';
import PropTypes from 'prop-types';
import HeaderQm from './HeaderQm';
import HeaderRanking from './HeaderRanking';
import HeaderSuperlist from './HeaderSuperlist';
import HeaderProductChecker from './HeaderProductChecker';

function Header({ dept, links, ...rest }) {
  switch (dept) {
    case 'product-checker':
      return (
        <HeaderProductChecker
          linksDept={links.product_checker}
          linksGeneral={links.qm}
          {...rest}
        />
      );
    case 'ranking':
      return (
        <HeaderRanking
          linksDept={links.ranking}
          linksGeneral={links.qm}
          {...rest}
        />
      );
    case 'superlist':
    case 'superlijst':
      return (
        <HeaderSuperlist
          linksDept={links.superlijst}
          linksGeneral={links.qm}
          {...rest}
        />
      );
    case 'qm':
    default:
      return <HeaderQm linksGeneral={links.qm} {...rest} />;
  }
}

Header.propTypes = {
  dept: PropTypes.string,
  links: PropTypes.shape({
    product_checker: PropTypes.array,
    ranking: PropTypes.array,
    superlijst: PropTypes.array,
    qm: PropTypes.array
  })
};

Header.defaultProps = {
  dept: 'qm'
};

export default Header;
