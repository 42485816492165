import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import Image from 'components/Image';

// @todo it doesn't make sense to call this component TopCertificates as we
// are not doing anything related to "top"ness of the certificate.
// This same module is used to render a normal certificate image.
// Rename this module to "Certificates"

function TopCertificates({ items, all, className, ...props }) {
  const MAX_ITEMS = 2;
  const extra = items.length > MAX_ITEMS ? items.length - MAX_ITEMS : 0;
  const _items = all ? items : items.slice(0, MAX_ITEMS);
  const items_without_images = items.filter((x) => !x.image_url).length;
  return (
    <div
      className={classnames('d-flex align-items-center', className)}
      {...props}>
      {_items
        .filter((x) => x.image_url)
        .map((item, index) => (
          <Image
            key={index}
            alt={item.name}
            title={item.name}
            className="img-fluid align-self-center pr-2"
            style={{ height: 25 }}
            src={item.image_url.thumb}
          />
        ))}
      {!all && extra > 0 && <span className="text-muted">+{extra}</span>}
      {all && items_without_images > 0 && (
        <span className="text-muted">+{items_without_images}</span>
      )}
    </div>
  );
}

TopCertificates.propTypes = {
  items: PropTypes.array,
  all: PropTypes.bool,
  className: PropTypes.string
};

TopCertificates.defaultProps = {
  items: [],
  all: false
};

export default TopCertificates;
