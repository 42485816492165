import React from 'react';
import PropTypes from 'prop-types';
import i18n from 'i18n';
import Image from 'components/Image';
const logo = 'superlist';

function LogoSuperlist({ lang, height, style, ...props }) {
  return (
    <Image
      src={`/static/images/${logo}-${lang}.svg`}
      alt={i18n.t('SECTION_SUPERLIST')}
      className="img-fluid"
      style={{ height, ...style }}
      {...props}
    />
  );
}

LogoSuperlist.propTypes = {
  lang: PropTypes.string.isRequired,
  height: PropTypes.number.isRequired,
  style: PropTypes.object
};

LogoSuperlist.defaultProps = {
  lang: 'nl',
  height: 50
};

export default LogoSuperlist;
