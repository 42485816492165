import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import media from 'components/Media';
import styled from 'styled-components';

export default function PageTitle({ center, pt, ...rest }) {
  return (
    <StyledH1
      className={classnames(`my-2 my-sm-4 pb-2 px-sm-0 pt-${pt}`, {
        'text-center': center,
        'px-4': !center
      })}
      {...rest}
    />
  );
}

PageTitle.defaultProps = {
  pt: 3
};

PageTitle.propTypes = {
  center: PropTypes.bool,
  pt: PropTypes.number.isRequired
};

// @todo - use <BreakWord> component here
const StyledH1 = styled.h1`
  ${media.down.tablet`
    word-wrap: break-word;
    hyphens: auto;
  `};
`;
