import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Link from 'components/Link';
import Logo from '../Logo/LogoQm';
import { getRootPath } from './HeaderGeneric';
import { MAIN_WEBSITE_URL } from '../../config';

function HeaderBannerQm({ lang }) {
  return (
    <Container>
      <Link route={MAIN_WEBSITE_URL || getRootPath(lang)}>
        <Logo small />
      </Link>
    </Container>
  );
}

export default HeaderBannerQm;

HeaderBannerQm.propTypes = {
  lang: PropTypes.string
};

const Container = styled.nav`
  a {
    display: inline-block;
    padding: 0 16px 4px 16px;
  }
`;
