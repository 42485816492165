import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

export const containerClasses = 'col-sm-10 offset-sm-1 px-0 px-sm-2';
export default function PageContainer({ fluid, ...props }) {
  return (
    <div
      className={classnames({ 'container-fluid': fluid, container: !fluid })}>
      <div className="row">
        <div className={containerClasses} {...props} />
      </div>
    </div>
  );
}

export const Wrap = (props) => <div className="px-4 px-sm-0" {...props} />;

PageContainer.propTypes = {
  fluid: PropTypes.bool
};

PageContainer.defaultProps = {
  fluid: false
};
