import React from 'react';
import PropTypes from 'prop-types';
import i18n from 'i18n';
import Image from 'components/Image';

function LogoQm({ small, style, ...props }) {
  const logo = small ? 'qm-logo-green-base' : 'qm-logo-ccc';
  return (
    <Image
      src={`/static/images/${logo}.svg`}
      alt={i18n.t('QUESTIONMARK')}
      className="img-fluid"
      style={{ height: small ? 14 : 54, ...style }}
      {...props}
    />
  );
}

LogoQm.propTypes = {
  small: PropTypes.bool.isRequired,
  style: PropTypes.object
};

LogoQm.defaultProps = {
  small: false
};

export default LogoQm;
