import React from 'react'; // eslint-disable-line
import styled from 'styled-components';

const Blockquote = styled.blockquote.attrs({
  className: 'h5 position-relative pt-sm-3'
})`
  &:before {
    content: '\\201C';
    color: ${(props) => props.theme.colors.sushi};
    font-size: 3em;
    position: absolute;
    top: 0;
    left: -20px;
  }

  &:after {
    content: '';
  }

  footer {
    font-size: 1rem;
    font-weight: normal;
  }
`;

export default Blockquote;
