import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Link from 'components/Link';
import Image from 'components/Image';
import i18n from 'i18n';

const SOCIAL = {
  twitter: 'https://twitter.com/Questionmark_NL',
  facebook: 'https://facebook.com/questionmarkfoundation',
  linkedin: 'https://www.linkedin.com/company/foundation-questionmark'
};

function getLinks(links, dept) {
  switch (dept) {
    case 'product-checker':
      return links.product_checker;
    case 'ranking':
      return links.ranking;
    case 'superlist':
    case 'superlijst':
      return links.superlijst;
    case 'qm':
    default:
      return links.qm;
  }
}

export default function Footer({ links, dept }) {
  const CurrentYear = new Date().getFullYear();
  const [first, second, ...last] = getLinks(links, dept);
  return (
    <StyledFooter className="footer">
      <div className="container">
        <div className="row">
          <div className="col-12 col-md-2 order-4 order-sm-0 mt-4">
            <div className="pb-5 border-bottom border-sushi-dark">
              <Image src="/static/images/social/qm.svg" alt="Questionmark" />
            </div>
            <div className="mt-5 mb-4">
              <Image
                src="/static/images/social/anbi.svg"
                alt="ANBI"
                width={100}
              />
            </div>
            <div className="my-4">
              <Image
                src="/static/images/social/stichting-doen.svg"
                alt="Stichting DOEN"
                width={100}
              />
            </div>
          </div>

          {[first, second].map((col, index) => (
            <FooterLinks
              col={col}
              className={`col-md-2 col-12 mt-4 ${!index ? 'offset-md-1' : ''}`}
              key={index}
            />
          ))}

          <div className="col-md-5 col-12 mt-4">
            <div className="row">
              {last.map((col, index) => (
                <FooterLinks
                  col={col}
                  className="col-12 col-md-6 mt-4 mt-md-0"
                  key={index}
                />
              ))}
            </div>
            <div className="mt-4" />
            <strong>{i18n.t('FOLLOW_US')}</strong>
            <br />
            {Object.keys(SOCIAL).map((key, index) => (
              <Link
                route={SOCIAL[key]}
                key={index}
                className="mr-5 my-4 d-inline-block">
                <Image src={`/static/images/social/${key}.svg`} alt={key} />
              </Link>
            ))}
          </div>
        </div>
        <Copyright>&copy; Questionmark {CurrentYear}</Copyright>
      </div>
    </StyledFooter>
  );
}

Footer.propTypes = {
  links: PropTypes.object.isRequired,
  dept: PropTypes.string.isRequired
};

Footer.defaultProps = {
  links: { qm: [] },
  dept: 'qm'
};

function FooterLinks({ col, ...props }) {
  if (!col) return null;
  return (
    <div className="col-md-2 col-6" {...props}>
      <strong>{col.name}</strong>
      <ul className="list list-unstyled row">
        {col.items.map(
          (item, idx) =>
            item.link && (
              <li className="col-6 col-md-12 mt-4" key={idx}>
                <Link route={item.link}>{item.name}</Link>
              </li>
            )
        )}
      </ul>
    </div>
  );
}

FooterLinks.propTypes = {
  col: PropTypes.object
};

const StyledFooter = styled.div.attrs({
  className: 'py-5'
})`
  color: ${(props) => props.theme.colors.ship};
  background: #fff;

  a {
    color: ${(props) => props.theme.colors.ship};
    &:hover {
      color: ${(props) => props.theme.colors.sushi};
    }
  }
`;

const Copyright = styled.div.attrs({
  className: 'text-small text-center my-3 my-sm-5'
})`
  color: #9f9fa3;
`;
