import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Form, Input, FormGroup, Button } from 'reactstrap';
import ThreeBounce from 'components/ThreeBounce';
import Share from 'components/Share';
import Adwords from 'components/Adwords';
import i18n from 'i18n';
import subscribe from '../lib/mailchimp';

class Subscribe extends Component {
  constructor(props) {
    super(props);
    if (!props.id) {
      throw new Error('You need a mailchimp id to use this component');
    }
    this.subscribe = this.subscribe.bind(this);
    this.state = {
      subscribing: false,
      subscribed: false,
      email: ''
    };
  }

  setEmail = (e) => {
    this.setState({
      email: e.target.value,
      valid: validate(e.target.value)
    });
  };

  render() {
    const { subscribing, subscribed, email, valid } = this.state;
    const { title } = this.props;
    return (
      <div className="mailchimp-subscribe">
        {!subscribed && (
          <Form inline className="mx-auto" onSubmit={this.subscribe}>
            <FormGroup>
              <Input
                type="email"
                bsSize="lg"
                placeholder={i18n.t('EMAIL_ADDRESS')}
                onChange={this.setEmail}
                value={email}
              />
            </FormGroup>
            &nbsp;
            <Button
              size="lg"
              color="ship-light"
              disabled={subscribing || !valid}>
              {subscribing && <ThreeBounce color="white" size={40} />}
              {title || i18n.t('SUBSCRIBE')}
            </Button>
          </Form>
        )}
        {subscribed && (
          <div>
            <h4 className="font-weight-light">{i18n.t('THANK_YOU')}</h4>
            <Share />
            <Adwords />
          </div>
        )}
      </div>
    );
  }

  subscribe(e) {
    e.preventDefault();
    this.setState({ subscribing: true });
    subscribe(this.state.email, this.props.id, this.context.lang)
      .then(() => this.setState({ subscribing: false, subscribed: true }))
      .catch((e) => {
        this.setState({ subscribing: false });
      });
  }
}

Subscribe.propTypes = {
  id: PropTypes.string.isRequired,
  title: PropTypes.string
};

Subscribe.contextTypes = {
  lang: PropTypes.string
};

export default Subscribe;

function validate(email) {
  return /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
    email
  );
}
