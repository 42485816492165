// see also bootstrap.scss
const primary = '#80bf26';
const accent = '#3f3e47';

const sushi = '#80bf26';
const sushiDark = '#63941e';
const ship = '#3f3e47';
const shipDark = '#27262c';
const shipLight = '#575760';
const shipLighter = '#eaeaea';
const corn = '#edc62b';
const flamingo = '#f5662b';
const flamingoDark = '#e24a0b';
/* @todo remove paradiso, replace it with another theme color */
const paradiso = '#367d85';
const paradisoDark = '#275b61';

const white = '#ffffff';
const grey = '#9f9fa3';
const lightGrey = '#f8f8f8';
const worst = flamingo;
const general = corn;

module.exports = {
  colors: {
    primary,
    accent,

    sushi,
    sushiDark,
    ship,
    shipDark,
    shipLight,
    shipLighter,
    corn,
    flamingo,
    flamingoDark,
    paradiso,
    paradisoDark,

    white,
    grey,
    lightGrey,
    worst,
    general,

    environment: '#2659bf',
    animals: '#99268f',
    social: '#a64921',
    health: '#26bfbf',

    health_scores: {
      C2: '#f5662b', // Nee
      C1: '#f5892b', // Niet echt
      B2: '#f0a42b', // Matig
      B1: '#edc62b', // Redelijk
      A: '#80bf26' // Ja
    }
  },

  bgColors: ['primary', 'paradiso', 'corn', 'flamingoDark', 'grey']
};
