import React from 'react';
import PropTypes from 'prop-types';
import i18n from 'i18n';
import Link from 'components/Link';
import Logo from 'components/Logo/LogoQm';
import HeaderGeneric, { getRootPath } from './HeaderGeneric';
import { MAIN_WEBSITE_URL } from '../../config';

// eslint-disable-next-line react/prop-types
const Brand = ({ lang }) => (
  <Link
    className="navbar-brand"
    route={MAIN_WEBSITE_URL || getRootPath(lang)}
    alt={i18n.t('QUESTIONMARK')}>
    <Logo />
  </Link>
);

function HeaderQm({ lang, ...rest }) {
  return <HeaderGeneric lang={lang} brand={<Brand lang={lang} />} {...rest} />;
}

HeaderQm.propTypes = {
  url: PropTypes.object,
  lang: PropTypes.string
};

export default HeaderQm;
