/* eslint-disable prettier/prettier */
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import classnames from 'classnames';
import {
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem as _DropdownItem,
  NavItem,
  NavLink,
  NavbarToggler,
  Collapse
} from 'reactstrap';
import media from 'components/Media';
import Link from 'components/Link';
import Toggler from 'components/Toggler';
import i18n from 'i18n';
import { isDevice } from 'lib/view-helpers';

import Caret from './Caret';
import Flag from './Flag';
import Nav from './Nav';

const locales = Object.keys(i18n.translations);

function PrismicNavItem({ type, name, items, link, level, ...rest }) {
  if (type === 'link' && level === 0) {
    return (
      <NavItem {...rest}>
        <NavLink route={link} tag={Link}>
          {name}
        </NavLink>
      </NavItem>
    );
  } else if (type === 'link' && level > 0) {
    return (
      <DropdownItem route={link} tag={Link} {...rest}>
        {name}
      </DropdownItem>
    );
  } else if (type === 'menu' && level === 0) {
    return (
      <UncontrolledDropdown nav inNavbar {...rest}>
        <DropdownToggle nav>
          {name} <Caret />
        </DropdownToggle>
        <DropdownMenu>
          <DropdownMenuArrow />
          {items.map((item, idx) => (
            <PrismicNavItem key={idx} level={level + 1} {...item} />
          ))}
        </DropdownMenu>
      </UncontrolledDropdown>
    );
  } else {
    /* only happens when there is no menu data yet */
    return null;
  }
}

// eslint-disable-next-line max-len
function Header({ brand, linksDept, linksGeneral, url, lang, className, searchComponent }) {
  return (
    <Toggler>
      {(toggle, open) => (
        <Nav
          className={classnames(
            'navbar navbar-light navbar-expand-lg py-3',
            className
          )}>
          {brand}
          <div className="row align-content-center">
            <NavbarToggler onClick={toggle} className="mr-2" />
          </div>

          <Collapse navbar isOpen={open}>
            {searchComponent}

            <ul className="navbar-nav ml-auto">
              {linksDept.map((item, index) => (
                <PrismicNavItem key={index} level={0} {...item} />
              ))}
              {linksGeneral.map((item, index) => (
                <PrismicNavItem key={index} level={0} className={(linksDept.length > 0 && index === 0 && 'nav-sep-left') || ''} {...item} />
              ))}
              <UncontrolledDropdown nav inNavbar>
                <DropdownToggle nav>
                  <Flag lang={lang} />
                  &nbsp;
                  {i18n.t('LANG_SHORT_' + lang.toUpperCase())} <Caret />
                </DropdownToggle>
                <DropdownMenu right={!isDevice('desktop')}>
                  <DropdownMenuArrow right={!isDevice('desktop')} />
                  {locales.map((locale, index) => (
                    <DropdownItem key={index} onClick={setLang(locale)}>
                      <Flag lang={locale} />
                      &nbsp;
                      {i18n.t('LANG_' + locale.toUpperCase())}
                    </DropdownItem>
                  ))}
                </DropdownMenu>
              </UncontrolledDropdown>
            </ul>
          </Collapse>
        </Nav>
      )}
    </Toggler>
  );
}

Header.propTypes = {
  linksGeneral: PropTypes.array.isRequired,
  linksDept: PropTypes.array.isRequired,
  url: PropTypes.object,
  lang: PropTypes.string,
  className: PropTypes.string,
  searchComponent: PropTypes.node,
  brand: PropTypes.node
};

Header.defaultProps = {
  linksDept: [],
  linksGeneral: [],
  url: {},
  lang: 'nl',
  searchComponent: null
};

export default Header;

function setLang(lang) {
  return (e) => {
    e.preventDefault();
    document.cookie = `lang=${lang};path=/;`;
    const path = getRootPath(lang);
    window.location = path;
  };
}

export function getRootPath(lang, path) {
  const base = lang === 'nl' ? '/' : '/en';
  if (path) {
    return base + (base === '/' ? '' : '/') + path;
  } else {
    return base;
  }
}

const DropdownItem = styled(_DropdownItem)`
  font-size: 0.9rem;
`;

const DropdownMenuArrow = styled.div`
  top: -25px;
  left: ${(props) => (props.right ? 80 : 20)}%;
  ${media.down.desktop`
    left: 10%;
  `}
  width: 0;
  height: 0;
  position: relative;
  &:before,
  &:after {
    content: '';
    position: absolute;
    display: block;
    width: 0;
    height: 0;
    border-width: 7px 8px;
    border-style: solid;
    border-color: transparent;
    z-index: 1001;
  }
  &:after {
    bottom: -18px;
    right: -8px;
    border-bottom-color: #fff;
  }
  &:before {
    bottom: -17px;
    right: -8px;
    border-bottom-color: rgba(0, 0, 0, 0.15);
  }
`;
