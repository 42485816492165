import React, { Component } from 'react'; // eslint-disable-line
import PropTypes from 'prop-types';
import moment from 'moment';
import i18n from 'i18n';

// TODO: Rename this component to something better like `Date`
// <Date date={...} /> => 12 Oct, 2018
// <Date date={...} fromNow /> => 2 months ago
// But we cannot call the component Date because it's a reserved keyword
// What would be a better name?

export function timeAgo(date, fromNow = false, lang = i18n.currentLocale()) {
  const d = date && moment(date).locale(lang);
  if (!d) return null;
  if (fromNow) return d.fromNow();
  return d.format('ll');
}

export default class TimeAgo extends Component {
  render() {
    const { date, fromNow } = this.props;
    return timeAgo(date, fromNow, this.context.lang);
  }
}

TimeAgo.propTypes = {
  date: PropTypes.instanceOf(Date),
  fromNow: PropTypes.bool
};

TimeAgo.contextTypes = {
  lang: PropTypes.string
};
