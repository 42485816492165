import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

function ThemeIcon({ name, inverted, className, ...props }) {
  const icon = `icon-${name}${inverted ? '-white' : ''}`;
  return (
    <div
      {...props}
      className={classnames(
        `d-flex align-items-center justify-content-center rounded-circle p-2 ${className}`
      )}>
      <img
        src={`/static/images/${icon}.svg`}
        alt={name}
        className="img-fluid w-100"
      />
    </div>
  );
}

ThemeIcon.propTypes = {
  name: PropTypes.string,
  inverted: PropTypes.bool,
  className: PropTypes.string
};

ThemeIcon.defaultProps = {
  className: ''
};

export default ThemeIcon;
