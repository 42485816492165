import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import i18n from 'i18n';
import Link from 'components/Link';
import Logo from 'components/Logo/LogoSuperlist';
import HeaderGeneric, { getRootPath } from './HeaderGeneric';
import HeaderBannerQm from './HeaderBannerQm';

// eslint-disable-next-line react/prop-types
const Brand = ({ lang }) => (
  <Link
    className="navbar-brand"
    route={getRootPath(lang, 'superlijst')}
    alt={i18n.t('SECTION_SUPERLIST')}>
    <Logo height={50} alt="" lang={lang} />
  </Link>
);

function HeaderSuperlist({ lang, ...rest }) {
  return (
    <Fragment>
      <HeaderBannerQm lang={lang} />
      <HeaderGeneric lang={lang} brand={<Brand lang={lang} />} {...rest} />
    </Fragment>
  );
}

HeaderSuperlist.propTypes = {
  lang: PropTypes.string
};

export default HeaderSuperlist;
