import styled from 'styled-components';
import media from 'components/Media';

// @todo
// check what should be the max width
const Nav = styled.nav`
  ${media.down.tablet`
    background: none !important;
  `};
  ul.navbar-nav li.nav-item {
    margin: 0 15px;
    font-size: 0.9rem;
    font-weight: 500;
    a.nav-link {
      color: ${(props) => props.theme.colors.ship};

      &:hover {
        color: ${(props) => props.theme.colors.sushi};
      }
    }

    &.active a.nav-link {
      color: ${(props) => props.theme.colors.sushi};
      border-bottom: 2px solid ${(props) => props.theme.colors.sushi};

      ${media.down.desktop`
        border-bottom: 0;
      `};
    }

    ${media.down.large`
      margin: 0 5px;
    `};

    ${media.down.desktop`
      margin: 0;
    `};

    &.nav-sep-left {
      border-color: ${(props) => props.theme.colors.white};
    }

    ${media.down.desktop`
      &.nav-sep-left  {
        border-top-width: 2px;
        border-top-style: solid;
        margin-top: 5px;
        padding-top: 5px;
      }
    `};

    ${media.up.desktop`
      &.nav-sep-left {
        border-left-width: 3px;
        border-left-style: solid;
        padding-left: 10px;
      }
    `};

    ${media.up.large`
      &.nav-sep-left {
        padding-left: 20px;
      }
    `};
  }

  ul.navbar-nav.bg-white li.nav-item.nav {
    ${media.up.tablet`
      &.nav-sep-left {
        border-color: ${(props) => props.theme.colors.lightGrey};
      }
    `};
  }

  .navbar-toggler {
    border: 0;
    color: ${(props) => props.theme.colors.sushi};
  }

  input {
    @media (max-width: 1100px) {
      width: 220px !important;
    }
    ${media.down.desktop`
      width: auto;
    `};
  }
`;
export default Nav;
