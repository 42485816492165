import React from 'react';
import PropTypes from 'prop-types';

import Image from 'components/Image';

function Flag({ lang }) {
  return (
    <Image
      src={`/static/images/icon-${lang}.svg`}
      className="img-fluid"
      alt={lang}
      style={{ height: 12, marginBottom: 3 }}
    />
  );
}

Flag.propTypes = {
  lang: PropTypes.string
};

Flag.defaultProps = {
  lang: 'nl'
};

export default Flag;
