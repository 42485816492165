import React, { Fragment, Component } from 'react';
import PropTypes from 'prop-types';
import ClipboardJS from 'clipboard';
import tippy from 'tippy.js';
import BoxWhite from 'components/BoxWhite';
import Image from 'components/Image';
import Link from 'components/Link';
import i18n from 'i18n';
import { FB_APP_ID } from 'config';

const facebook = '/static/images/facebook.png';
const twitter = '/static/images/twitter.png';
const whatsapp = '/static/images/whatsapp.png';
const email = '/static/images/email.png';

export default class Share extends Component {
  constructor(props) {
    super(props);
    this.copyBtn = React.createRef();
    this.state = {
      url: ''
    };
  }

  componentDidMount() {
    this.setState({ url: window.location.href });
    var clipboard = new ClipboardJS(this.copyBtn.current);
    clipboard.on('success', (e) => {
      const tip = tippy(this.copyBtn.current, {
        content: i18n.t('COPIED'),
        arrow: true,
        delay: [100, 0]
      });
      tip && tip.show();
    });
  }

  render() {
    const { url } = this.state;
    const { title } = this.props;
    return (
      <Fragment>
        <div>
          {FB_APP_ID && (
            <Fragment>
              <a
                href={`https://www.facebook.com/dialog/share?app_id=${FB_APP_ID}&display=popup&href=${url}&redirect_uri=${url}`}
                rel="noopener noreferrer"
                target="_blank">
                <Image
                  className="img-fluid"
                  src={facebook}
                  width={50}
                  height={50}
                />
              </a>
              &nbsp;&nbsp;
            </Fragment>
          )}
          <a
            href={`https://twitter.com/intent/tweet?text=${
              title || ''
            }&url=${url}`}
            rel="noopener noreferrer"
            target="_blank">
            <Image className="img-fluid" src={twitter} width={50} height={50} />
          </a>
          &nbsp;&nbsp;
          <a
            href={`https://wa.me/?text=${title || ''} ${url}`}
            rel="noopener noreferrer"
            target="_blank">
            <Image
              className="img-fluid"
              src={whatsapp}
              width={50}
              height={50}
            />
          </a>
          &nbsp;&nbsp;
          <a
            href={`mailto:?subject=${title}&body=${url}`}
            rel="noopener noreferrer"
            target="_blank">
            <Image className="img-fluid" src={email} width={50} height={50} />
          </a>
        </div>
        <div className="mt-4 mx-3">
          <div
            className="input-group input-group-sm mx-auto"
            style={{ maxWidth: 400 }}>
            <input
              type="text"
              className="form-control"
              defaultValue={url}
              id="copy"
            />
            <div className="input-group-append">
              <button
                ref={this.copyBtn}
                className="btn btn-outline-secondary"
                type="button"
                data-clipboard-action="copy"
                data-clipboard-target="#copy">
                <i className="fa fa-share" /> {i18n.t('COPY')}
              </button>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

Share.propTypes = {
  title: PropTypes.string
};

// @todo
// Make this responsive

// This component needs to be stateful component for react-sticky

export class ShareContent extends Component {
  state = {
    url: this.props.url
  };

  componentDidMount() {
    this.setState({
      url: window.location.href
    });
  }

  render() {
    const { title, url, ...props } = this.props;
    const _url = encodeURIComponent(this.state.url);
    const _title = encodeURIComponent(title);
    return (
      <BoxWhite className="p-4 d-none d-lg-block" {...props}>
        <strong>{i18n.t('SHARE')}</strong>
        <div className="mt-2">
          {FB_APP_ID && (
            <div className="row py-2">
              <Link
                className="col"
                target="_blank"
                rel="noopener"
                route={`https://www.facebook.com/dialog/share?app_id=${FB_APP_ID}&display=popup&href=${_url}&redirect_uri=${_url}`}>
                <Image
                  className="img-fluid"
                  src="/static/images/social/facebook.svg"
                  style={{ height: 24, marginRight: 18 }}
                  alt=""
                />{' '}
                Facebook
              </Link>
            </div>
          )}
          <div className="row py-2">
            <Link
              className="col"
              target="_blank"
              rel="noopener"
              route={`https://www.linkedin.com/shareArticle?url=${_url}&title=${_title}`}>
              <Image
                className="img-fluid"
                src="/static/images/social/linkedin.svg"
                style={{ height: 24, marginRight: 18 }}
                alt=""
              />{' '}
              LinkedIn
            </Link>
          </div>
          <div className="row py-2">
            <Link
              className="col"
              target="_blank"
              rel="noopener"
              route={`https://twitter.com/intent/tweet?text=${_title}&url=${_url}`}>
              <Image
                className="img-fluid"
                src="/static/images/social/twitter.svg"
                style={{ height: 24, marginRight: 18 }}
                alt=""
              />{' '}
              Twitter
            </Link>
          </div>
        </div>
      </BoxWhite>
    );
  }
}

ShareContent.propTypes = {
  title: PropTypes.string,
  url: PropTypes.string
};
