import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import classnames from 'classnames';
import { Row, Col, Button } from 'reactstrap';
import Link from 'components/Link';
import media from 'components/Media';
import Image from 'components/Image';
import TopCertificates from 'components/TopCertificates';
import i18n from 'i18n';
import HealthScore from 'components/HealthScore';
import slugify from 'lib/slugify';

export default function ProductListItem({
  item,
  search,
  onSelect,
  selected,
  disabled,
  ...props
}) {
  const { id, name, brand, image, personal_health_score, certificates } = item;
  const brandName = brand && brand.name;
  const btnCompareColor = disabled
    ? 'outline-grey'
    : selected
    ? 'paradiso'
    : 'outline-paradiso';
  return (
    <div
      className="list-group-item list-group-item-action py-4 py-lg-3 px-3"
      {...props}>
      <div className="media">
        <Image
          alt={name}
          src={image.thumb || '/static/images/img-placeholder.svg'}
          width={30}
          style={{ maxHeight: 60, marginRight: 15 }}
        />
        <div className="media-body position-relative">
          <Row className="align-items-lg-center">
            <Col xs={12} sm={12} md={12} lg={6}>
              <BrandName title={brandName}>{brandName}</BrandName>
              <PorductName className="list-group-item-heading text-ship mt-1">
                <Link
                  title={name}
                  route={`/products/${slugify(id, name)}`}
                  onClick={props.onClick}
                  className="d-block">
                  {name}
                </Link>
              </PorductName>
            </Col>
            <Col xs={12} sm={12} md={12} lg={6} className="position-static">
              <div className="d-flex small text-grey mb-1 mb-lg-0">
                <HealthScoreWrapper>
                  <div className="d-block d-lg-none">{i18n.t('HEALTHY')}</div>
                  <HealthScore score={personal_health_score} />
                  {!personal_health_score && '--'}
                </HealthScoreWrapper>
                <div
                  className={classnames(
                    'align-self-lg-center mt-3 mt-lg-0 m-lg-0',
                    {
                      'ml-lg-5': !search,
                      'ml-lg-3': search
                    }
                  )}>
                  <TopCertificates items={certificates.top} />
                  {!certificates.top.length && <span>--&nbsp;&nbsp;</span>}
                </div>
                {!search && onSelect && (
                  <div className="my-lg-0 align-self-center mt-2 mt-lg-0 ml-auto">
                    <Button
                      className="ml-2"
                      color={btnCompareColor}
                      size="sm"
                      disabled={disabled}
                      onClick={onSelect}>
                      {i18n.t('COMPARE')}
                    </Button>
                  </div>
                )}
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
}

ProductListItem.propTypes = {
  search: PropTypes.bool,
  onSelect: PropTypes.func,
  selected: PropTypes.bool,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  item: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    brand: PropTypes.object,
    image: PropTypes.shape({ thumb: PropTypes.string }),
    personal_health_score: PropTypes.string,
    certificates: PropTypes.shape({
      top: PropTypes.array
    }),
    score: PropTypes.number
  })
};

const Font14 = styled.div`
  font-size: 0.934rem; // 14px;
`;

const BrandName = styled(Font14)`
  font-weight: 500;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  color: ${(props) => props.theme.colors.shipLight};
`;

const PorductName = styled(Font14)`
  ${media.down.desktop`
    max-width: calc(100% - 60px);
  `}
`;

const HealthScoreWrapper = styled.div`
  width: 50px;
  text-align: center;
  ${media.down.desktop`
    position: absolute;
    top: -10px;
    right: 0;
  `};
`;
