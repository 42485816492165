import React, { Component } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Link as _Link } from 'router';

export default class Link extends Component {
  render() {
    const { route, disabled, children, ...rest } = this.props;
    const { app } = this.context;

    if (!route) return <a {...rest}>{children}</a>;

    let url = route || '';
    const isExternal =
      url.startsWith('http:') ||
      url.startsWith('https:') ||
      url.startsWith('mail:') ||
      url.startsWith('tel:');
    const isDownload = url.startsWith('/download/');

    if (app) {
      url =
        url +
        (url.includes('?') ? '&' : '?') +
        'app=' +
        encodeURIComponent(app);
    }
    if (isExternal || isDownload) {
      return (
        <a {...rest} href={url}>
          {children}
        </a>
      );
    }
    if (disabled) {
      return <a {...rest}>{children}</a>;
    }
    return (
      <_Link route={url}>
        <a {...rest}>{children}</a>
      </_Link>
    );
  }
}

Link.propTypes = {
  route: PropTypes.string,
  disabled: PropTypes.bool,
  children: PropTypes.node
};

Link.contextTypes = {
  app: PropTypes.string
};

Link.defaultProps = {
  route: ''
};

const StyledLink = styled(Link)`
  font-size: 0.8rem;
  font-weight: 500;
  text-transform: uppercase;
  text-decoration: none;
  padding-bottom: 2px;
  border-bottom: 1px dotted ${(props) => props.theme.colors.primary};
  &:hover {
    text-decoration: none;
    border-bottom-style: solid;
  }
`;

export const LinkRight = styled(StyledLink)`
  &:after {
    content: ' →';
  }
`;

export const LinkDown = styled(StyledLink)`
  &:after {
    content: ' ↓';
  }
`;

export const LinkUp = styled(StyledLink)`
  &:after {
    content: ' ↑';
  }
`;
