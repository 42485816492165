import PrismicDOM from 'prismic-dom';
import linkResolver from 'router/link-resolver';

export function commonPageAttrs(data) {
  return {
    cta: getCTA(data),
    meta: getMetaInfo(data),
    related: getRelated(data)
  };
}

export function getMetaInfo({
  meta_title,
  meta_description,
  image,
  hidden_seo
}) {
  return {
    title: meta_title,
    description: meta_description,
    image: image && image.url,
    hidden: hidden_seo && hidden_seo === 'true'
  };
}

export function getText(field) {
  if (!field) return '';
  if (!hasText(field)) return '';
  return PrismicDOM.RichText.asHtml(field, linkResolver);
}

export function getFirstParagraph(doc) {
  return doc.reduce((res, d) => {
    if (res) return res;
    if (d.type === 'paragraph') return d.text;
  }, '');
}

export function getExcerpt(text, words) {
  if (!text) return '';
  return text.split(' ').slice(0, words).join(' ');
}

export function getRelated({ related = [] }) {
  return related
    .filter((item) => item.name && item.link)
    .map((item) => ({
      title: item.name,
      description: getText(item.description),
      link_url: linkResolver(item.link)
    }));
}

export function getCTA({
  cta_title,
  cta_description = '',
  cta_link_title,
  cta_link_url,
  cta_subscribe,
  cta_share
}) {
  if (!cta_title || !cta_link_title || !(cta_link_url || cta_subscribe)) return;
  return {
    title: cta_title,
    description: getText(cta_description),
    link_title: cta_link_title,
    link_url: linkResolver(cta_link_url),
    subscribe: cta_subscribe,
    share: !!cta_share
  };
}

export function hasText(text) {
  return PrismicDOM.RichText.asText(text).trim().length > 0;
}
