import React from 'react';
import PropTypes from 'prop-types';
import slugify from 'lib/slugify';
import styled from 'styled-components';
import media from 'components/Media';

export default function InPageNav({ blocks, ...props }) {
  return (
    <div {...props}>
      <NavContainer className="p-4 rounded bg-white mb-3">
        <ul className="pl-3 mb-0">
          {blocks.map((block) => (
            <li key={block.title}>
              <a href={`#${slugify(block.title)}`}>{block.title} &darr;</a>
            </li>
          ))}
        </ul>
      </NavContainer>
    </div>
  );
}

InPageNav.propTypes = {
  blocks: PropTypes.array
};

const NavContainer = styled.div`
  position: absolute;
  ${media.down.desktop`
    position: static;
  `};
`;
