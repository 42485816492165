const localized = require('./localized-types');

module.exports = function linkResolver(doc) {
  if (!doc) return null;

  if (doc && doc.link_type && doc.link_type.toLowerCase() === 'web') {
    // return url, but make absolute URLs to the QM website relative
    return doc.url.replace(
      /^https?:\/\/checker(-web)?(\.staging)?\.thequestionmark\.org/,
      ''
    );
  }
  const path = (doc.lang && localized[doc.lang][doc.type]) || doc.type;
  const prefix = doc.lang === 'nl-nl' ? '' : '/en';

  switch (doc.type) {
    case 'team':
    case 'press':
    case 'faq':
    case 'donate':
    case 'case_list':
    case 'about_main':
      return `${prefix}/${path}`;
    case 'wijzer':
      return `${prefix}/qm-wijzer/${doc.uid}`;
    case 'landing':
      return `${prefix}/${doc.uid}`;
    case 'about':
    case 'blog':
    case 'case':
    case 'page':
    case 'impact':
      return `${prefix}/${path}/${doc.uid}`;
    case 'home':
      return prefix;
    case 'blog_list':
      return `${prefix}/blog`;
    case 'impact_list':
      return `${prefix}/impact`;
    case 'wijzer_list':
      return `${prefix}/qm-wijzer`;
    case 'categories':
      return '/categories';
    case 'contact':
      return `${prefix}/contact`;
    default:
      return null;
  }
};
