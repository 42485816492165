import React from 'react';
import PropTypes from 'prop-types';
import Link from 'components/Link';
import Markup from 'components/Markup';
import Subscribe from 'components/Subscribe';
import Share from 'components/Share';

export default function PageCTA({ cta }) {
  if (!cta) return null;
  const { title, description, link_url, link_title, subscribe, share } = cta;
  if (!(title && ((link_title && link_url) || subscribe))) return null;
  return (
    <div className="py-5">
      <div className="container px-4 px-sm-3 my-2">
        <h3>{title}</h3>
        <Markup className="font-weight-light">{description}</Markup>
        <div className="mt-4">
          {subscribe && <Subscribe id={subscribe} title={link_title} />}
          {!subscribe && link_url && (
            <Link route={link_url} className="btn btn-ship-light">
              {link_title}
            </Link>
          )}
          {share && <Share />}
        </div>
      </div>
    </div>
  );
}

PageCTA.propTypes = {
  cta: PropTypes.shape({
    title: PropTypes.string,
    description: PropTypes.string,
    link_url: PropTypes.string,
    link_title: PropTypes.string,
    subscribe: PropTypes.string,
    share: PropTypes.bool
  })
};
