import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import i18n from 'i18n';
import Link from 'components/Link';
import Logo from 'components/Logo/LogoProductChecker';
import HeaderGeneric, { getRootPath } from './HeaderGeneric';
import HeaderBannerQm from './HeaderBannerQm';

// eslint-disable-next-line react/prop-types
const Brand = ({ lang }) => (
  <Link
    className="navbar-brand"
    route={getRootPath(lang, 'product-checker')}
    alt={i18n.t('SECTION_PRODUCT_CHECKER')}>
    <Logo height={32} /> {i18n.t('SECTION_PRODUCT_CHECKER')}
  </Link>
);

function HeaderProductChecker({ url, lang, ...rest }) {
  return (
    <Fragment>
      <HeaderBannerQm lang={lang} />
      <HeaderGeneric lang={lang} brand={<Brand lang={lang} />} {...rest} />
    </Fragment>
  );
}

HeaderProductChecker.propTypes = {
  url: PropTypes.object,
  lang: PropTypes.string
};

export default HeaderProductChecker;
