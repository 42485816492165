import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import media from 'components/Media';

export default function BoxWhite({ iff, children, ...rest }) {
  if (!iff) return null;
  return <BoxWhiteStyled {...rest}>{children}</BoxWhiteStyled>;
}

const BoxWhiteStyled = styled.div`
  border-radius: 5px;
  box-shadow: 0 3px 7px rgba(0, 0, 0, 0.2);
  background: white;
  ${media.down.phone`
    border-radius: 0;
  `};
`;

BoxWhite.propTypes = {
  iff: PropTypes.bool,
  children: PropTypes.node
};

BoxWhite.defaultProps = {
  iff: true
};
