import React from 'react';
import PropTypes from 'prop-types';
import i18n from 'i18n';
import Image from 'components/Image';
const logo = 'icon-ranking-bold';
const logoGrey = 'icon-ranking-bold-grey';

function LogoRanking({ height, style, grey, ...props }) {
  return (
    <Image
      src={`/static/images/${grey ? logoGrey : logo}.svg`}
      alt={i18n.t('SECTION_PRODUCT_CHECKER', { count: 4 })}
      className="img-fluid"
      style={{ height, ...style }}
      {...props}
    />
  );
}

LogoRanking.propTypes = {
  height: PropTypes.number.isRequired,
  grey: PropTypes.bool,
  style: PropTypes.object
};

LogoRanking.defaultProps = {
  height: 50,
  grey: false
};

export default LogoRanking;
