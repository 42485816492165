import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Markup from 'components/Markup';
import theme from 'lib/theme';
import { HEALTH_SCORES } from '../constants';
import i18n from 'i18n';
export default function HealthScore({ score, ...rest }) {
  if (!score) return null;
  const text = i18n.t(`HEALTH_${score}`);
  const words = text.split(' ');
  let content = words[0];
  if (words.length > 1) {
    content = words.map((word, index) => (
      <tspan y={45 + 20 * index} x={50} key={index}>
        {word}
      </tspan>
    ));
  }
  return (
    <Wrapper {...rest} title={text}>
      <svg width="100%" height="100%" viewBox="0 0 100 100">
        <circle
          cx="50%"
          cy="50%"
          r="40"
          stroke={theme.colors.health_scores[score]}
          strokeWidth="12"
          fill="white"
        />
        <text
          x="50%"
          y="55%"
          textAnchor="middle"
          fontSize="18"
          fontWeight="500"
          color="black">
          {content}
        </text>
      </svg>
    </Wrapper>
  );
}

HealthScore.propTypes = {
  score: PropTypes.oneOf(HEALTH_SCORES)
};

export function HealthScoreSentence({ score }) {
  const healthScore = i18n.t(`HEALTHINESS_SCORE_${score.toUpperCase()}`);

  return (
    <StyledMarkup score={score}>
      {i18n.t('HEALTHINESS_OF', { score: healthScore.toLowerCase() })}
    </StyledMarkup>
  );
}

HealthScoreSentence.propTypes = {
  score: PropTypes.oneOf(HEALTH_SCORES)
};

const Wrapper = styled.div`
  max-width: 50px;
  height: 50px;
`;

const StyledMarkup = styled(Markup)`
  b {
    color: ${(props) => props.theme.colors.health_scores[props.score]};
  }
`;
