import React from 'react';
import styled from 'styled-components';
import { Button } from 'reactstrap';
import i18n from 'i18n';

export default function Back(props) {
  if (!isClient()) return null;
  if (isClient() && !hasHistory()) {
    return (
      <a href={getParent()} className="btn btn-outline-primary mt-4">
        {i18n.t('BACK')}
      </a>
    );
  }
  return (
    <a onClick={back} href="" className="btn btn-outline-primary mt-4">
      {i18n.t('BACK')}
    </a>
  );
}

export function BackApp(props) {
  if (isClient() && !hasHistory()) return null;
  return <StyledBackButton onClick={back}>{i18n.t('BACK')}</StyledBackButton>;
}

function isClient() {
  return typeof window !== 'undefined';
}

function hasHistory() {
  return window.history.length > 2;
}

function getParent() {
  // remove initial empty entry
  // /qm-wijzer/stompot
  // ["", "qm-wijzer", "stompot"]

  // eslint-disable-next-line
  const [parent, current] = window.location.pathname.split('/').slice(1, 3);
  // ["qm-wijzer", "stompot"]
  // /qm-wijzer

  // @todo - this is a dirty hack, we should address this in router redesign
  // https://github.com/q-m/questionmark-web/issues/128
  // for /dossair/slug we need the parent to be a plural
  const _parent = ['dossier'].includes(parent) ? parent + 's' : '';
  return `/${_parent}`;
}

function back(e) {
  e.preventDefault();
  window.history.back();
}

const StyledBackButton = styled(Button).attrs({
  color: 'outline-primary',
  size: 'sm'
})`
  margin-left: 15px;
`;
