import React from 'react';
import PropTypes from 'prop-types';
import { useRouter } from 'next/router';
import Link from 'components/Link';
import i18n from 'i18n';

export default function LeftSidebar({ items }) {
  const { asPath } = useRouter();
  return (
    <div className=" mt-5">
      <b>{i18n.t('SIDE_NAV_TITLE')}</b>
      <ul className="pl-3 mt-2">
        {items.map((item) => (
          <li key={item.name}>
            {item.type === 'link' && item.link !== asPath && (
              <Link route={item.link}>{item.name}</Link>
            )}
            {item.type === 'link' && item.link === asPath && item.name}
            {item.type === 'menu' && (
              <>
                {item.name}
                <ul>
                  {item.items.map((x) => (
                    <li key={x.link}>
                      {x.link !== asPath && (
                        <Link route={x.link}>{x.name}</Link>
                      )}
                      {x.link === asPath && x.name}
                    </li>
                  ))}
                </ul>
              </>
            )}
          </li>
        ))}
      </ul>
    </div>
  );
}

LeftSidebar.propTypes = {
  items: PropTypes.array
};
