import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import striptags from 'striptags';

// It's nicer to avoid writing `dangerouslySetInnerHTML` all the time
// when you want to display markup content.
// <Markup>{content}</Markup> is much nicer

function Markup({ children, component, only, ...rest }) {
  const html = only ? striptags(children, only) : children;
  return React.createElement(component, {
    ...rest,
    dangerouslySetInnerHTML: { __html: html }
  });
}

Markup.propTypes = {
  children: PropTypes.node,
  component: PropTypes.string,
  only: PropTypes.arrayOf(PropTypes.string),
  intro: PropTypes.bool
};

Markup.defaultProps = {
  component: 'div'
};

export const limited = [
  'ul',
  'ol',
  'li',
  'a',
  'em',
  'i',
  'strong',
  'b',
  'p',
  'span'
];

export default styled(({ collapsible, intro, ...props }) => (
  <Markup {...props} />
))`
  img {
    display: block;
    padding: 20px 0;
    max-width: 100%;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  .h1,
  .h2,
  .h3,
  .h4,
  .h5,
  .h6 {
    margin-bottom: 1rem;
    margin-top: 3rem;
  }

  ${(props) =>
    props.collapsible &&
    `
  p:last-child {
    margin: 0;
  }
  `};

  ${(props) =>
    props.intro &&
    `
    font-size: 1.4rem;
  `};
`;
