import React, { Component } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import media from 'components/Media';
import Image from 'components/Image';
import BoxWhite from 'components/BoxWhite';
import { Wrap } from 'components/PageContainer';
import Markup from 'components/Markup';
import Blockquote from 'components/Blockquote';
import ThemeIcon from 'components/ThemeIcon';
import ListCards from 'components/ListCards';
import classnames from 'classnames';
import slugify from 'lib/slugify';

// Overlapping styles are defined in components/Layout.js
// .overlap-x-lg
// .overlap-y-lg

// Block alignment, in that order
const alignment = ['col-lg-8', 'offset-lg-3', 'offset-lg-1 col-lg-8', ''];

// Some guidelines for coloring the blocks
// https://github.com/q-m/questionmark-web/wiki/Design

const presets = {
  white: [],
  sushi: [
    ['bg-white block-white', 'border-bottom'],
    ['bg-white block-sushi'],
    ['bg-white block-ship'],
    ['bg-white block-white', 'border-bottom'],
    ['bg-white block-sushi'],
    ['bg-white block-ship']
  ],
  flamingo: [
    ['bg-white block-white', 'border-bottom'],
    ['bg-white block-flamingo'],
    ['bg-white block-ship'],
    ['bg-white block-corn'],
    ['bg-white block-white', 'border-bottom'],
    ['bg-white block-ship'],
    ['bg-white block-flamingo']
  ],
  corn: [
    ['bg-white block-corn', 'border-bottom'],
    ['bg-white block-ship'],
    ['bg-white block-white', 'border-bottom'],
    ['bg-white block-corn'],
    ['bg-white block-ship']
  ],
  ship: [
    ['bg-white block-ship'],
    ['bg-white block-corn'],
    ['bg-white block-white', 'border-bottom'],
    ['bg-white block-flamingo'],
    ['bg-white block-ship'],
    ['bg-white block-corn'],
    ['bg-white block-white', 'border-bottom'],
    ['bg-white block-flamingo']
  ],
  // @deprecated copy of ship for now
  paradiso: [
    ['bg-white block-ship'],
    ['bg-white block-corn'],
    ['bg-white block-white', 'border-bottom'],
    ['bg-white block-flamingo'],
    ['bg-white block-ship'],
    ['bg-white block-corn'],
    ['bg-white block-white', 'border-bottom'],
    ['bg-white block-flamingo']
  ]
};

class Blocks extends Component {
  render() {
    const { items } = this.props;
    // make a copy coz we are splicing it later
    const preset = [...presets[this.props.preset]];
    return (
      <div className="my-0 my-sm-4">
        {items
          .map((item, idx) => addPreset(item, preset, idx === items.length - 1))
          .map((item, idx) => addOverlap(item, idx, items))
          .map((item, index) => (
            <div
              className={classnames('d-flex', {
                'flex-column flex-lg-row': item.type === 'title_desc_and_image',
                'overlap-y-lg': canOverlap(item, index, items)
              })}
              key={index}>
              <a name={slugify(item.title)} />
              <Block {...item} idx={index} />
            </div>
          ))}
      </div>
    );
  }
}

Blocks.propTypes = {
  items: PropTypes.array.isRequired,
  preset: PropTypes.oneOf(Object.keys(presets)).isRequired
};

Blocks.defaultProps = {
  items: [],
  preset: 'white'
};

export default Blocks;

function Block({
  idx,
  type,
  title,
  theme_icon,
  description,
  image,
  image_at_bottom,
  video,
  quote,
  cite,
  logos,
  preset,
  cards,
  overlapStyles,
  ...props
}) {
  // We are only aligning the first few blocks.
  const align = alignment[idx]
    ? alignment[idx]
    : idx % 2 === 0
    ? 'offset-lg-3'
    : 'col-lg-8';
  const common = 'w-100'; // IE 11 issue

  if (type === 'title_and_desc') {
    const classes = 'my-0 my-sm-4 p-4 p-sm-5 ';
    return (
      <BoxWhiteWithIcon
        className={classnames(common, classes, align, preset, {
          'with-icon': theme_icon
        })}
        style={overlapStyles}>
        <TitleWithIcon
          className={classnames('d-flex align-items-start', {
            'with-icon': theme_icon
          })}>
          {theme_icon && (
            <div>
              <ThemeIcon name={theme_icon} style={{ width: 50 }} />
            </div>
          )}
          <h2>{title}</h2>
        </TitleWithIcon>
        <Markup>{description}</Markup>
      </BoxWhiteWithIcon>
    );
  } else if (type === 'title_desc_and_image') {
    const classes = 'my-0 my-sm-4 p-4 p-sm-5';
    const imageUrl = (image.thumb && image.thumb.url) || image.url;
    return (
      <BoxWhite
        className={classnames(common, classes, {
          'd-flex flex-column flex-lg-row': !image_at_bottom,
          [align]: image_at_bottom
        })}>
        {!image_at_bottom && (
          <>
            <div className="align-self-center col-12 col-lg-4 p-0">
              <Image src={imageUrl} className="img-fluid d-block mx-auto p-4" />
            </div>
            <div className="col-12 col-lg-8 mt-4 mt-lg-0 p-0">
              <h2>{title}</h2>
              <Markup>{description}</Markup>
            </div>
          </>
        )}
        {image_at_bottom && (
          <>
            <h2>{title}</h2>
            <Markup>{description}</Markup>
            <Image src={image.url} className="img-fluid d-block mx-auto pt-3" />
          </>
        )}
      </BoxWhite>
    );
  } else if (type === 'quote' && quote) {
    const classes = 'my-0 my-sm-4 p-5';
    return (
      <BoxWhite
        className={classnames(common, classes, align, preset)}
        style={overlapStyles}>
        <Blockquote>
          <Markup>{quote}</Markup>
          {cite && (
            <footer className="blockquote-footer">
              <cite title="Source Title">{cite}</cite>
            </footer>
          )}
        </Blockquote>
      </BoxWhite>
    );
  } else if (type === 'image' && image.url) {
    return (
      <div className={classnames(common, align, 'p-0 my-0 my-sm-4')}>
        <Image src={image.url} className="img-fluid" />
      </div>
    );
  } else if (type === 'video') {
    const html = <Markup>{video.html}</Markup>;
    if (video.type === 'video') {
      const classes = 'embed-responsive embed-responsive-16by9 my-0 my-sm-4';
      return <div className={classnames(common, classes, align)}>{html}</div>;
    } else {
      return (
        <Wrap style={overlapStyles}>
          <div className={align}>{html}</div>
        </Wrap>
      );
    }
  } else if (type === 'logos' && logos.length > 0) {
    const classes = 'my-sm-4 p-4 p-sm-0';
    return (
      <div className={classnames(common, classes)}>
        {title && <h2>{title}</h2>}
        <div className="my-4">
          {logos.map((logo, index) => (
            <Logo
              src={logo.url}
              key={index}
              alt={`Logo ${index}`}
              className="img-fluid pr-3 my-3"
            />
          ))}
        </div>
      </div>
    );
  } else if (type === 'cards' && cards.length > 0) {
    return <ListCards items={cards} />;
  }
  return null;
}

Block.propTypes = {
  idx: PropTypes.number,
  type: PropTypes.string,
  title: PropTypes.string,
  theme_icon: PropTypes.string,
  description: PropTypes.string,
  image: PropTypes.object,
  image_at_bottom: PropTypes.bool,
  video: PropTypes.object,
  quote: PropTypes.string,
  cite: PropTypes.string,
  logos: PropTypes.array,
  preset: PropTypes.array,
  cards: PropTypes.array,
  overlapStyles: PropTypes.object
};

function addPreset(item, preset, last) {
  item.preset = getPreset(item, preset, last);
  return item;
}

const allowedPresetTypes = ['title_and_desc', 'quote'];

function getPreset(item, preset, last) {
  let result = [];
  if (allowedPresetTypes.includes(item.type)) {
    const colors = preset.splice(0, 1);

    // We don't want the last block to be flamingo because
    // the call to action is also flamingo
    if (!(colors.join(', ').includes('flamingo') && last)) {
      result = colors.length > 0 ? colors : ['border-bottom'];
    }
  }
  return result;
}

const overlappingTypes = ['title_and_desc', 'quote'];

function canOverlap(item, index, items) {
  return (
    index &&
    overlappingTypes.includes(item.type) &&
    overlappingTypes.includes(items[index - 1].type)
  );
}

function addOverlap(item, index, items) {
  item.overlapStyles = { zIndex: 100 + index };
  return item;
}

const Logo = styled(Image)`
  filter: grayscale(100%);
  opacity: 0.8;
  height: 100px;
`;

const BoxWhiteWithIcon = styled(BoxWhite)`
  &.with-icon {
    ${media.up.phone`
      padding-left: 4.7rem !important;
    `};
  }
`;

const TitleWithIcon = styled.div`
  &.with-icon {
    ${media.up.phone`
      margin-left: -50px;
    `};
  }
`;
