import React, { Component } from 'react'; // eslint-disable-line
import styled from 'styled-components';
import PropTypes from 'prop-types';

export default class Toggler extends Component {
  state = { toggled: !!this.props.initiallyToggled };

  componentDidMount() {
    this.props.onMount && this.props.onMount(this.toggle, this.state.toggled);
  }

  toggle = (e) => {
    e && e.preventDefault && e.preventDefault();
    // scroll the user back to the viewport he was in
    if (this.state.toggled && this.state.scrollYOffset) {
      window.scrollTo({ top: this.state.scrollYOffset });
    }
    this.setState({
      toggled: !this.state.toggled,
      scrollYOffset: window.pageYOffset
    });
  };

  render() {
    return this.props.children(this.toggle, this.state.toggled);
  }
}

Toggler.propTypes = {
  initiallyToggled: PropTypes.bool,
  onMount: PropTypes.func,
  children: PropTypes.func.isRequired
};

export const Toggle = styled.span`
  cursor: pointer;
  &:after {
    font-family: 'Font Awesome 6 Free';
    font-weight: 900;
    font-style: normal;
    font-variant: normal;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    content: "${(props) => (props.toggled ? '\f077' : '\f078')}";
  }
`;
