import React from 'react';
import PropTypes from 'prop-types';
import Head from 'next/head';
import theme from 'lib/theme';
import { isProdRuntime } from '../config';

function Meta({ title, description, image, url, hidden }) {
  return (
    <Head>
      {title && <title>{title}</title>}
      {description && <meta name="description" content={description} />}

      {(!isProdRuntime || hidden) && (
        <meta name="robots" content="noindex, nofollow, noarchive" />
      )}

      {/* PWA */}
      {/* https://developers.google.com/web/fundamentals/engage-and-retain/web-app-manifest/ */}
      {/* Icons generated using https://app-manifest.firebaseapp.com/ */}
      <link rel="manifest" href="/static/manifest.json" />
      <meta name="theme-color" content={theme.colors.sushi} />

      <meta property="og:type" content="website" />
      <meta property="og:site_name" content="Questionmark" />
      {title && <meta property="og:title" content={title} />}
      {description && <meta property="og:description" content={description} />}
      {image && <meta property="og:image" content={image} />}
      {url && <meta property="og:url" content={url} />}

      {/* Generated from http://faviconit.com/en */}
      <link rel="shortcut icon" href="/static/images/favicons/favicon.ico" />
      <link
        rel="icon"
        sizes="16x16 32x32 64x64"
        href="/static/images/favicons/favicon.ico"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="196x196"
        href="/static/images/favicons/favicon-192.png"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="160x160"
        href="/static/images/favicons/favicon-160.png"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="96x96"
        href="/static/images/favicons/favicon-96.png"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="64x64"
        href="/static/images/favicons/favicon-64.png"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="32x32"
        href="/static/images/favicons/favicon-32.png"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="16x16"
        href="/static/images/favicons/favicon-16.png"
      />
      <link
        rel="apple-touch-icon"
        href="/static/images/favicons/favicon-57.png"
      />
      <link
        rel="apple-touch-icon"
        sizes="114x114"
        href="/static/images/favicons/favicon-114.png"
      />
      <link
        rel="apple-touch-icon"
        sizes="72x72"
        href="/static/images/favicons/favicon-72.png"
      />
      <link
        rel="apple-touch-icon"
        sizes="144x144"
        href="/static/images/favicons/favicon-144.png"
      />
      <link
        rel="apple-touch-icon"
        sizes="60x60"
        href="/static/images/favicons/favicon-60.png"
      />
      <link
        rel="apple-touch-icon"
        sizes="120x120"
        href="/static/images/favicons/favicon-120.png"
      />
      <link
        rel="apple-touch-icon"
        sizes="76x76"
        href="/static/images/favicons/favicon-76.png"
      />
      <link
        rel="apple-touch-icon"
        sizes="152x152"
        href="/static/images/favicons/favicon-152.png"
      />
      <link
        rel="apple-touch-icon"
        sizes="180x180"
        href="/static/images/favicons/favicon-180.png"
      />
      <meta name="msapplication-TileColor" content="#FFFFFF" />
      <meta
        name="msapplication-TileImage"
        content="/static/images/favicons/favicon-144.png"
      />
      <meta name="msapplication-config" content="/static/browserconfig.xml" />
    </Head>
  );
}

Meta.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  image: PropTypes.string,
  url: PropTypes.string,
  hidden: PropTypes.bool
};

Meta.defaultProps = {
  title: '',
  description: '',
  image: '',
  url: '',
  hidden: false
};

export default Meta;
