import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import styled from 'styled-components';
import Markup from 'components/Markup';
import Link from 'components/Link';
import i18n from 'i18n';

export default function Related({ items }) {
  if (!items) return null;
  if (!items.length) return null;
  const related = items.filter((i) => i.title && i.link_url);
  if (!related.length) return null;
  return (
    <div className="py-5">
      <div className="container px-4 px-sm-3 my-2">
        {related.map((item, index) => (
          <div key={index} className={classnames({ 'mt-5': index > 0 })}>
            <h3>{item.title}</h3>
            <Markup className="text-muted font-weight-light">
              {item.description}
            </Markup>
            <div className="mt-3">
              <LinkStyled route={item.link_url}>
                {item.link_title || i18n.t('READ_MORE')}
              </LinkStyled>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

Related.propTypes = {
  items: PropTypes.array.isRequired
};

Related.defaultProps = {
  items: []
};

const LinkStyled = styled(Link)`
  color: ${(props) => props.theme.colors.primary} !important;
  text-decoration: none !important;
  padding-bottom: 3px;
  color: ${(props) => props.theme.colors.sushi};
  border-bottom: 2px solid ${(props) => props.theme.colors.sushi};
  text-transform: uppercase;
  &:hover {
    border-bottom: 0;
  }
`;
