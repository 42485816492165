import PrismicDOM from 'prismic-dom';
import linkResolver from 'router/link-resolver';
import {
  commonPageAttrs,
  getText,
  getExcerpt,
  getFirstParagraph
} from './utils';

export default {
  nav,
  home,
  about: landing,
  blog,
  blogPost,
  faq,
  team,
  press,
  wijzers,
  wijzer,
  page,
  case_files,
  case_file: landing,
  impacts,
  impact: landing,
  publications,
  landing,
  products,
  categories,
  brand,
  contact,
  compare,
  donate
};

function nav({ data = {} }) {
  const footerItems = navItemsOld(data.body);
  return {
    header: {
      qm: navItems(data.body_qm),
      ranking: navItems(data.body_ranking),
      superlijst: navItems(data.body_superlijst),
      product_checker: navItems(data.body_product_checker)
    },
    footer: {
      qm: footerItems,
      ranking: footerItems,
      superlijst: footerItems,
      product_checker: footerItems
    },
    sidebar: {
      superlijst: navItems(data.sidenav_superlijst)
    }
  };
}

function navItemsOld(items = []) {
  return items
    .map(({ slice_type: type, items, primary }) => ({
      type,
      items,
      ...primary
    }))
    .map(({ items, ...rest }) => ({
      ...rest,
      items: items
        .map((item) => ({ ...item, link: linkResolver(item.link) }))
        .filter(({ name, link }) => name && link)
    }));
}

function navItems(items = []) {
  return items.map(function ({ slice_type: type, items, primary }) {
    switch (type) {
      case 'link':
        return { ...primary, type, link: linkResolver(primary.link) };
      case 'menu':
        return {
          ...primary,
          type,
          items: items.map(({ name, link }) => ({
            name,
            type: 'link',
            link: linkResolver(link)
          }))
        };
    }
  });
}

function home({ data }) {
  return {
    notice: getText(data.notice),
    cover_image: data.cover_image.url,
    cover_image_tablet: data.cover_image_tablet.url,
    head_line: data.head_line,
    head_text: getText(data.head_text),
    notice_text: data.notice_text,
    notice_bg: data.notice_bg,
    notice_image: data.notice_img.url,
    notice_url: linkResolver(data.notice_url),
    impact_title: data.impact_title,
    press: data.press.map((p) => p.item.url),
    stats: data.stats,
    wijzer: {
      title: data.featured_title,
      description: getText(data.featured_description),
      items: data.wijzer
        .map((w) => ({
          ...w,
          link: w.link.uid ? linkResolver(w.link) : w.link.url
        }))
        .filter(({ name, link }) => name && link)
    },
    ...commonPageAttrs(data)
  };
}

function blogPost({ uid, data, type, lang }) {
  return {
    url: linkResolver({ uid, type, lang }),
    uid,
    published_on: data.published_date && PrismicDOM.Date(data.published_date),
    name: data.name,
    excerpt: getExcerpt(getFirstParagraph(data.body), 20),
    body: getText(data.body),
    cover_image: data.cover_image && data.cover_image.url,
    ...commonPageAttrs(data)
  };
}

function faq({ data }) {
  return {
    name: data.name,
    description: getText(data.description),
    faqs: data.faqs.map((doc) => ({
      question: doc.question,
      answer: getText(doc.answer)
    })),
    ...commonPageAttrs(data)
  };
}

function team({ data }) {
  return {
    people: data.people.map((person) => ({
      name: person.name,
      avatar: person.avatar.url,
      role: getText(person.position),
      linkedin_url: linkResolver(person.linkedin_url)
    })),
    ...commonPageAttrs(data)
  };
}

function press({ data }) {
  const field = (name) => (result, { primary }) =>
    result || getText(primary[name]);
  const list = data.body.reduce(
    (result, { items }) => result.concat(items),
    []
  );
  const description = data.body.reduce(field('description'), '');
  const name = data.body.reduce(
    (result, { primary }) => result || primary.name,
    ''
  );
  return {
    name,
    description,
    items: list
      .map((item) => ({
        name: item.name,
        date: item.date,
        imageUrl: item.image.url,
        linkUrl: item.link.uid ? linkResolver(item.link) : item.link.url,
        quote: getText(item.quote)
      }))
      .sort((a, b) => new Date(b.date) - new Date(a.date)),
    ...commonPageAttrs(data)
  };
}

function wijzer({ data, uid, type, lang }) {
  return {
    url: linkResolver({ uid, type, lang }),
    disabled: data.disabled && data.disabled.toLowerCase() === 'yes',
    updated_on: data.published_date && PrismicDOM.Date(data.published_date),
    title: data.name,
    intro: getText(data.description),
    cover_image_url: data.cover_image
      ? { medium: data.cover_image.thumb.url, large: data.cover_image.url }
      : {},
    products: data.csv_json && data.csv_json.products,
    headers: data.csv_json && data.csv_json.headers,
    renews: data.renewed && data.renewed.toLowerCase() === 'yes',
    weights: (data.distribution || []).filter((d) => d.title && d.percent),
    quotes: (data.quotes || [])
      .filter((i) => i.quote && i.quote.length > 0)
      .map(({ quote, source }) => ({
        text: getText(quote),
        author: source
      })),
    disclaimer: getText(data.disclaimer),
    ...commonPageAttrs(data)
  };
}

function page(type) {
  return ({ uid, data, lang }) => ({
    url: linkResolver({ uid, type, lang }),
    uid,
    published_on: data.published_date && PrismicDOM.Date(data.published_date),
    dept: data.dept,
    name: data.name,
    excerpt: getExcerpt(getFirstParagraph(data.body), 20),
    body: getText(data.body),
    blocks: (data.slices || []).map(block),
    preset: data.preset || 'white',
    display_in_page_nav:
      typeof data.display_in_page_nav === 'boolean'
        ? data.display_in_page_nav
        : true, // we need this check coz there's a default true in prismic
    // unfortunately the prismic api does not render the default for
    // already published pages - this is a flaw in the api
    ...commonPageAttrs(data)
  });
}

function landing({ uid, data, type, lang }) {
  return {
    url: linkResolver({ uid, type, lang }),
    uid,
    published_on: data.published_date && PrismicDOM.Date(data.published_date),
    dept: data.dept,
    name: data.name,
    preset: data.preset || 'white', // for block coloring
    excerpt: getExcerpt(getFirstParagraph(data.description), 20),
    body: getText(data.description),
    blocks: data.body.map(block),
    cover_image: data.cover_image && data.cover_image.url,
    display_in_page_nav: data.display_in_page_nav,
    ...commonPageAttrs(data)
  };
}

function products({ data, type, lang }) {
  return {
    sustainability_info: getText(data.sustainability_info),
    sustainability_intro_link: linkResolver(data.sustainability_intro_link),
    sustainability_brand_report_info: getText(
      data.sustainability_brand_report_info
    ),
    source_info: getText(data.source_info),
    supermarket_availability: getText(data.supermarket_availability),
    about_health_info: getText(data.about_health_info),
    about_sustainability_info: getText(data.about_sustainability_info),
    no_sustainability_info: getText(data.no_sustainability_info),
    no_nutrients_info: getText(data.no_nutrients_info),
    no_ingredients_info: getText(data.no_ingredients_info),
    no_health_scores_info: getText(data.no_health_scores_info),
    no_top_certificates_info: getText(data.no_top_certificates_info),
    no_brand_or_certificate_info: getText(data.no_brand_or_certificate_info),
    no_theme_info: getText(data.no_theme_info),
    no_supermarkets_info: getText(data.no_supermarkets_info),
    top_certificates_info: getText(data.top_certificates_info),
    ...commonPageAttrs(data)
  };
}

function brand({ data }) {
  return {
    intro_link: linkResolver(data.intro_link),
    top_cert_description: getText(data.top_cert_description),
    health_scores_description: getText(data.health_scores_description),
    ...commonPageAttrs(data)
  };
}

function contact({ data, type, lang }) {
  return {
    body: getText(data.body),
    ...commonPageAttrs(data)
  };
}

function publication({ uid, data, type, lang }) {
  return {
    url: linkResolver({ uid, type, lang }),
    preview_image: data.preview_image,
    uid,
    published_on: data.published_date && PrismicDOM.Date(data.published_date),
    dept: data.dept,
    name: data.name,
    body: getText(data.description),
    pending: !!data.pending,
    document: data.document && {
      url: data.document.url,
      size: data.document.size
    },
    ...commonPageAttrs(data)
  };
}

function block({ slice_type: type, ...block }) {
  if (type === 'title_and_desc' || type === 'title_desc_and_image') {
    return {
      ...block.primary,
      description: getText(block.primary.description),
      type
    };
  } else if (type === 'quote') {
    return {
      quote: getText(block.primary.quote),
      cite: block.primary.cite,
      type
    };
  } else if (type === 'logos') {
    return {
      ...block.primary,
      logos: block.items.map((i) => i.logo).filter((i) => i),
      type
    };
  } else if (type === 'cards') {
    return {
      ...block.primary,
      cards: block.items.map((card) => ({
        ...card,
        cover_image: card.cover_image.url,
        url: linkResolver(card.link)
      })),
      type
    };
  }
  return { type, ...block.primary };
}

function categories({ data }) {
  return {
    body: getText(data.body),
    ...commonPageAttrs(data)
  };
}

function wijzers(...params) {
  return listPages('wijzer_list', wijzer, ...params);
}

function blog(...params) {
  return listPages('blog_list', blogPost, ...params);
}

function case_files(...params) {
  return listPages('case_list', landing, ...params);
}

function impacts(...params) {
  return listPages('impact_list', landing, ...params);
}

function publications(...params) {
  return listPages('publication_list', publication, ...params);
}

function listPages(type, transformer, { results, single }) {
  return {
    ...commonPageAttrs(single.data),
    description: getText(single.data.description),
    results: results.map(transformer)
  };
}

function compare({ data, type, lang }) {
  return {
    ...commonPageAttrs(data)
  };
}

function donate({ data, type, lang }) {
  return {
    name: data.name,
    body: getText(data.body),
    body_2: getText(data.body_2),
    thank_body: getText(data.thank_body),
    list_id: data.list_id,
    payment: {
      description: data.payment_description,
      default_amount: data.payment_default_amount,
      amounts: (data.payment_amounts_csv || '').split(/\s*,\s*/)
    },
    ...commonPageAttrs(data)
  };
}
